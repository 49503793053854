<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/task.png"
             alt />
        <span class="center">发放明细</span>
        <img src="../../assets/right.png"
             alt />
        <span class="center">发放列表</span>
        <img src="../../assets/right.png"
             alt />
        <span class="center">发放详情</span>
        <span style="color: cornflowerblue; margin-left: 20px"
              @click="goBack">返回</span>
      </div>
      <div style="display: flex; align-items: center"></div>
    </div>
    <el-card class="box-card">
      <Divider :title="'基本信息'"></Divider>
      <el-descriptions :column="3">
        <el-descriptions-item label="发放企业">
          {{
          detailInfo.enterpriseName
          }}
        </el-descriptions-item>
        <el-descriptions-item label="结算金额">
          {{
          detailInfo.total
          }}
        </el-descriptions-item>
        <el-descriptions-item label="支付状态">
          <el-tag v-if="detailInfo.payState==='0'"
                  type="success">待发放</el-tag>
          <el-tag v-if="detailInfo.payState==='1'"
                  type="success">已完成</el-tag>
          <el-tag v-if="detailInfo.payState==='3'"
                  type="warning">部分完成</el-tag>
          <el-tag v-if="detailInfo.payState==='2'"
                  type="danger">失败</el-tag>
        </el-descriptions-item>

        <el-descriptions-item label="支付时间">
          {{
          detailInfo.payTime
          }}
        </el-descriptions-item>
      </el-descriptions>
      <Divider :title="'支付信息'"></Divider>
      <el-table :data="detailInfo.datas"
                style="width: 100%">
        <el-table-column prop="userName"
                         label="姓名"
                         fixed></el-table-column>
        <el-table-column prop="userIdCardNumber"
                         label="身份证号"></el-table-column>
        <el-table-column label="时薪">
          <template slot-scope="scope">
            <span>{{ JSON.parse(scope.row.other).hourSalary }}</span>
          </template>
        </el-table-column>

        <el-table-column label="出勤工时">
          <template slot-scope="scope">
            <span>{{ JSON.parse(scope.row.other).workDays.length * 4 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="出勤天数">
          <template slot-scope="scope">
            <span>{{ JSON.parse(scope.row.other).workDays.length}}</span>
          </template>
        </el-table-column>
        <el-table-column v-for="(item, index) in headerTitle"
                         :key="index"
                         :label="item">
          <template slot-scope="scope">
            {{
            JSON.parse(scope.row.other)[item]
            }}
          </template>
        </el-table-column>
        <el-table-column label="结算金额">
          <template slot-scope="scope">
            <span>{{ scope.row.salary }}</span>
          </template>
        </el-table-column>
        <el-table-column label="支付结果"
                         fixed="right">
          <template slot-scope="scope">
            <span v-if="scope.row.payState==='1'">支付成功</span>
            <span v-if="scope.row.payState==='2'"
                  style="color:#ec808d">支付失败</span>
            <span v-if="scope.row.payState==='0'">支付中</span>
            <span v-if="scope.row.payState==='-1'">未支付</span>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>
<script>
import { api } from '../../api/base/index';
export const upperEnterpriseDispatchInfo = api()('upper_enterprise.dispatch.info.json');
export default {
  components: {
    Divider: () => import('../../components/divider.vue'),
  },
  data () {
    return {
      code: '',
      detailInfo: {},
      activeName: '',
      headerTitle: [],

      obj: {},
    };
  },
  mounted () {
    this.code = this.$route.query.code;
    this.loadData();
  },
  methods: {
    loadData () {
      const params = {
        code: this.code,
      };
      upperEnterpriseDispatchInfo(params).then((res) => {
        this.detailInfo = res;

        this.obj = JSON.parse(res.datas[0].other);
        console.log(typeof (this.obj));
        var reg = /^[\u4E00-\u9FA5]+$/;
        for (var key in this.obj) {
          if (reg.test(key)) {
            this.headerTitle.push(key);
          }
        }

        console.log(this.headerTitle, this.detailInfo);
      });
    },
    goBack () {
      this.$router.go(-1);
    },
    handleSizeChange (val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange (val) {
      this.pageNumber = val;
      this.loadData();
    },
  },
};
</script>

